@import "checkboxes";


.gm-style {
    button[title="Close"] {
        img {
            width: 19px !important;
            height: 19px !important;
            margin: 7px 9px !important;
        }
    }

    .gm-style-iw { /* google maps info window */
        font-size: 1rem;
        padding: 11px 11px 0 11px;
        .controls {
            &.top {
                form {
                    select.pin-problem-selector {
                        width: auto;
                        min-width: 120px;
                        font-weight: bold;
                        color: #252c3c;
                        background-color: #FAFAFA;
                        border: solid 1px #252c3c;
                        &:focus {
                            border-color: #EB6C00;
                            box-shadow: 0 1px 0 0 #EB6C00;
                            outline: 0;
                        }
                    }
                }
            }
            &.bottom {
                a {
                    display: inline-block;
                    padding: 10px 0;
                    text-decoration: none;
                    img {
                        margin-right: 5px;
                    }
                }
                a.guide-me-link {
                    padding-right: 10px;
                    font-weight: 400;
                    color: #ff6100;
                }
                a.delete-pin-link {
                    margin-left: 30px;
                    padding-left: 10px;
                    font-weight: 500;
                    color: #fc2c2c;
                    float: right;
                }
            }
        }
    }
}

.map-field-images {

    .pin-visibility-check-parent {
        padding-top: 75px;
    }

    div.col-header-col {
        margin-top: 22px;
    }

    .availableImageDropdownParent3 {
        display: none;
    }
    .availableImageDropdownParent6 {
        display: none;
    }
    .availableImageDropdownParent9 {
        display: none;
    }

    img.downloading-img {
        width: 25px;
    }

    p.downloading {
        font-size: 12px;
        line-height: 1.33;
        text-align: center;
        color: #5d6e80;
        padding: 5px;
        margin: 0;
    }

    .pin-action-check-parent {
        margin-top: 10px;
        margin-right: 20px;
    }

    .mobile-priority {
        display: none;
    }

}

a.cta.cta-white.icon-left.edit-field-disabled {
    color: gray;
    background-color: lightgray;
    cursor: not-allowed;
    img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
}

.field-map-spinner-background {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 170px;
    height: 130px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
}

.field-map-spinner {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 170px;
    height: 130px;
    font-size: 14px;
    img.downloading-img {
        width: 35px;
        display: block;
        margin: 25px auto 10px;
    }
    p {
        text-align: center;
        margin: 0;
    }
}

@media (max-width: 1800px) {

    .availableImageButtonParent9 {
        display: none;
    }
    .availableImageDropdownParent9 {
        display: block !important;
    }

}

@media (max-width: 940px) {

    .availableImageButtonParent6 {
        display: none;
    }
    .availableImageDropdownParent6 {
        display: block !important;
    }

}
@media (max-width: 700px) {
    .map-field-images {
        div.col-header-col {
            margin-top: 8px;

        }

        div.row {
            margin-right: -46px;
        }

        div.col-header-col.left-button {
            margin-left: -24px !important;
        }

        div.col-header-col.right-button {
            margin-right: -24px !important;
        }

        .map-images-edit-icon {
            display: none;
        }

        .pin-action-check-parent {

            margin-top: -13px;
            margin-right: -13px;

        }

    }

    .image-type-bar {
        margin-top: -50px !important;
    }

    .availableImageButtonParent3 {
        display: none;
    }

    .availableImageDropdownParent3 {
        display: block !important;
    }

    .mobile-priority {
        display: block !important;
    }

    .desktop-priority {
        display: none;
    }

}
@media (max-width: 650px) {

    .availableImageButtonParent3 {
        display: none;
    }

    .availableImageDropdownParent3 {
        display: block !important;
    }

}

@media (max-width: 400px) {
    .gm-style .gm-style-iw .controls.bottom a {
        padding: 10px 10px 10px 0;
        display: block;
        &.delete-pin-link {
            padding-left: 0;
            float: none;
            margin: 0;
        }
    }
}

