@import "variables";
@import "mixins";
@import "map-field-images";

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: #f3f6f8;
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #242c3d;
    font-size: $body-text !important;
    line-height: 24px;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin-bottom: 10px;
    font-family: Ubuntu;
}

h1 {
    position: relative;
    z-index: 100;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 38px;
    line-height: 47px;
    font-weight: 400;
    letter-spacing: 2px;
}

h2 {
    position: relative;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 42px;
    line-height: 45px;
    font-weight: 400;
}

h2.small {
    font-size: 37px;
}

h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
}

h4 {
    font-weight: 300;
    font-size: 23px;
    line-height: 28px;
}

h5 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
}

h6 {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

a {
    background-color: transparent;
    color: $copy-colour;
    text-decoration: underline;
}

a.orange-text {
    color: $call-to-action-colour !important;
    cursor: pointer;
}

a:active,
a:hover {
    outline: 0;
}

a:focus {
    outline: 0;
}

.languages {
    a {
        @include opacity(0.6);
        margin-left: 5px;
        display: inline-block;
        cursor: pointer;
    }
    a.active {
        @include opacity(1);
    }
}

ul,
ol {
    margin-top: 0px;
    margin-bottom: 0;
    padding-left: 25px;
}

label {
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
    color: $copy-colour;
    font-weight: 400;
    font-size: inherit;
}

input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime],
input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea, .w-input, .w-select, select.pin-problem-selector {
    display: block;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 10px;
    font-size: 1em;
    line-height: 1.428571429;
    color: #333333;
    vertical-align: middle;
    background-color: #FAFAFA;
    border: 1px solid #b2bac3;
    height: 42px;
    border-radius: 5px;
    box-sizing: border-box;
}

[type="checkbox"] + label {
    margin-top: 5px;
    font-size: $body-text;
    line-height: 24px;
}

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
    border-color: $copy-colour;
    @include border-radius(3px);
    width: 20px;
    height: 20px;
}

[type="checkbox"] + label.white-checkbox {
    color: white;
}

[type="checkbox"] + label.white-checkbox:before,
[type="checkbox"]:not(.filled-in) + label.white-checkbox:after {
    border-color: white;
}

[type="checkbox"]:checked + label.white-checkbox:before {
    border-top: 2px solid transparent;
    border-left: 2px solid transparent
}

.pdf-embed {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object {
        border: 0;
        display: block;
        width: 100%;
        height: 100%;
        height: 100vh;
    }
}

/* Generic styles start */
.how-to-use-maps-container {
    img.play-btn {
        margin-right: 5px;
        vertical-align: top;
    }
    a { /* styles which will apply to both link styles */
        text-decoration: none;
        font-size: $small-text;
        text-align: left;
    }
    .how-to-use-maps-with-birdie {
        img.birdie {
            display: inline-block;
            margin-right: 10px;
            width: 77px;
            height: auto;
            vertical-align: top;
            margin-top: -10px;
        }
        a {
            @include border-radius(30px);
            background: white;
            display: inline-block;
            position: relative;
            padding: 8px;
            &:before {
                content: '';
                width: 0;
                height: 0;
                border: 12px solid transparent;
                border-right-color: white;
                position: absolute;
                top: 8px;
                left: -19px;
            }
        }
    }
    a.how-to-use-maps {
        @include border-radius(30px);
        display: inline-block;
        color: white;
        padding: 6px 12px 6px 8px;
        background: rgba(0, 0, 0, 0.4);
        text-shadow: 0px 0px 2px black;
        margin: 0 auto;
    }
}

.pale-border {
    border: 1px solid #D3D8DD;
}

.container.main {
    padding-top: 30px;
}

.tick {
    position: relative;
}

.tick:before {
    z-index: 6;
    content: "";
    position: absolute;
    top: -3px;
    left: 30px;
    width: 22px;
    height: 22px;
    background: $primary-green 49% 50% url("/static/img/tick-white.svg") no-repeat;
    border: 2px solid $site-background-colour;
    @include border-radius(100%);
}

.tile,
.collapsible li {
    position: relative;
    @include border-radius(6px);
    @include tile-box-shadow;
    background-color: white;
    padding: $tile-padding;
    vertical-align: top;
    margin-bottom: $tile-margin-bottom;
}

.collapsible li {
    border: 0;
}

.dropdown {
    .dropdown-button.image-type {
        margin-right: 0;
        img.arrow {
            float: right;
            margin: 1px 12px 0 0;
            width: 12px;
            @include transition(0.4s);
        }
        img.arrow.closed {
            @include rotate(-180deg);
            margin-top: 5px;
        }
    }

    .dropdown-content {
        @include transition(0.2s);
        margin-top: 3px;
        opacity: 1;
        display: block;
        overflow: hidden;
        &.hidden {
            max-height: 0;
        }
        .selected-map-image-type {
            border-radius: 0 !important;
        }
        li {
            min-height: 0;
        }
    }
}

.text-right {
    text-align: right;
}

/* Generic styles end */

.form-top-section {
    padding-bottom: 60px;
    background-image: -webkit-radial-gradient(circle farthest-side at 50% 0%, #1bc06c, #52a964);
    background-image: radial-gradient(circle farthest-side at 50% 0%, #1bc06c, #52a964);
}

.form-top-section.done {
    height: 350px;
}

.form-top-section.project-page {
    padding-bottom: 120px;
}

.logo {
    width: 222px;
    margin-left: -30px;
    padding: 13px 27px;
    float: left;
}

.logo-img {
    width: 100%;
    margin-left: -3px;
}

.container {
    position: relative;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
}

.container.why {
    z-index: 1;
    display: block;
    width: 100%;
}

.container.top {
    display: block;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
    text-align: left;
}

.welcome-message {
    display: inline-block;
    max-width: 700px;
    margin-top: 50px;
    margin-right: 25px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: 43px;
    line-height: 50px;
    text-align: left;
}

.header-section {
    padding: 15px;
    background-color: #242c3d;
}

.header-section.form-header {
    border-width: 0;
}

.arrow-left {
    position: absolute;
    left: -32px;
    bottom: 47px;
    display: none;
    width: 0;
    height: 0;
    border-right: 32px solid #fff;
    border-bottom: 30px solid transparent;
    border-left: 0 solid #fff;
}

.arrow-left-inside {
    display: none;
    width: 0;
    height: 0;
    margin-top: 3px;
    margin-left: 8px;
    border-right: 25px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 0 solid transparent;
}

.why-section {
    position: relative;
    padding-top: 50px;
    padding-bottom: 0;
    background-color: #fff;
    .health-map img {
        max-width: 600px;
    }
}

.contact-form {
    float: right;
    .submit-button-arrow {
        width: 100%;
    }
}

.contact-form.tile {
    position: relative;
    z-index: 500;
    display: inline-block;
    margin-bottom: 0;
    padding: 20px;
    float: none;
    background: transparent;
}

.contact-form.tile.form-on-form-page {
    z-index: 0;
    display: block;
    width: auto;
    max-width: 500px;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 15px;
    float: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.why-block {
    display: block;
    width: 60%;
    float: left;
    clear: left;
}

.about-me-list {
    margin-top: 22px;
}

.list-item {
    padding: 7px 10px 7px 0;
    border-radius: 6px;
    background-color: transparent;
}

.round-tick {
    width: 24px;
    height: 24px;
    padding-right: 1px;
    float: left;
    border: 2px solid #00a777;

    border-radius: 100%;
    text-align: center;
    position: relative;
    img {
        @include centralise;
        width: 66%;
        height: auto;
        margin-top: 1px;
    }
}

.bold-text {
    color: #4b535b;
    font-weight: 700;
}

.green-text {
    color: #00A777 !important;
}

.about-me-text {
    margin-top: 0;
    margin-left: 36px;
    line-height: 1.4em;
}

.lighter-text {
    color: #5D6E80;
}

.cta,
.cta-white {
    padding: 11px 25px;
    border-radius: 5px;
    background-color: #fc6d1d;
    box-shadow: 0 1px 2px 0 rgba(36, 44, 61, .45);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
}

.cta-white {
    background-color: white;
    color: $call-to-action-colour;
}

.cta + .cta {
    margin-left: 10px;
}

.cta:hover {
    background-color: #ff6e00;
}

.cta-white:hover {
    background-color: white;
}

.cta.float-right {
    float: right;
}

.cta:disabled {
    @include opacity(0.5);
}

.cta.icon-left,
.cta.icon-right {
    padding-left: 10px;
    padding-right: 10px;
    img {
        vertical-align: text-top;
        margin: -3px 5px 0 0;
    }
}

.cta.icon-right img {
    margin: 2px 0 0 10px;
}

.cta.icon-left.marker {
    img {
        vertical-align: bottom;
    }
}

.cta.submit-button-arrow {
    margin-top: 6px;
    padding-right: 40px;
    background-image: url('/static/img/arrow-forward.svg');
    background-position: 92% 50%;
    background-size: 11px;
    background-repeat: no-repeat;
    font-weight: 400;
}

.cta.cta-large {
    min-width: 200px;
    margin-top: 30px;
}

.cta.cta-large.get-image {
    min-width: 280px;
}

.cta.cta-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.testimonials-section {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    .container {

    }
}

.field.textarea-input {
    height: 120px;
}

.field.email {
    margin-bottom: 2px;
}

.testimonials-wrapper {
    position: relative;
    display: block;
    margin: 50px auto 0 auto;
    padding-right: 10px;
    padding-left: 10px;
}

.testimonial-tile {
    position: relative;
    display: inline-block;
    width: 30%;
    height: 100%;
    margin-right: 15px;
    margin-left: 15px;
    padding: 20px;
    border-width: 7px;
    border-color: #d5dade;
    border-bottom-style: solid;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .4);
}

.quote-marks-left {
    position: absolute;
    left: -70px;
    top: -20px;
}

.quote-marks-right {
    position: absolute;
    top: -20px;
    right: -70px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.bottom-section {
    padding-top: 50px;
    padding-bottom: 90px;
    background-color: #fff;
    text-align: center;
}

.about-section {
    background: center url("/static/img/location-map.jpg") no-repeat;
    background-size: cover;
    padding-bottom: 15px;
    .about-block {
        position: relative;
        top: -40px;
        left: 0;
        max-width: 400px;
        h4 {
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 15px;
        }
        img {
            margin-bottom: 0.5em;
        }
    }
}

.footer-white-text {
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    color: #242c3d;
}

.footer {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #f4f6f8;
    text-align: center;
    border-top: 1px solid #D1D4D7;
}

.footer.white-text {
    position: relative;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 19px 20px 11px;
}

.small-text {
    margin-top: 5px;
    font-size: 14px;
}

.small-text.bold-text.center-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-align: center;
}

.footer-disclaimer {
    color: #8a9199;
    font-size: 13px;
}

.terms-link {
    color: #8a9199;
}

.hero-img {
    position: relative;
    top: 38px;
    height: 100%;
    float: left;
}

.logo-container {
    margin-top: 50px;
}

.media-logo {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 25px 35px 0;
}

.media-logo.integrisense {
    top: -5px;
    margin-left: 20px;
}

.media-logo.afro {
    top: 0;
}

.form-nav {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.form-nav-item {
    position: relative;
    z-index: 100;
    display: inline-block;
    width: 100px;
}

.form-nav-item.basics {
    left: -60px;
    top: 4px;
    float: left;
}

.form-nav-item.quote {
    left: 60px;
    top: 17px;
    float: right;
}

.form-nav-container {
    clear: both;
    color: #fff;
    text-align: center;
}

.line {
    position: relative;
    top: 38px;
    z-index: 99;
    display: block;
    width: 100%;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
}

.form-nav-item-text {
    margin-top: 6px;
    font-size: 14px;
}

.form-nav-item-text.white-text {
    display: block;
}

.optional-tag {
    font-size: 13px;
    font-style: italic;
}

.caption {
    margin-top: -6px;
    margin-bottom: 19px;
    color: #5d6e80;
    font-size: 13px;
    line-height: 18px;
    font-style: italic;
}

.tool-tip {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border: 2px solid #1ab265;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 1px 7px 4px rgba(93, 110, 128, .55);
    text-align: center;
}

.tool-tip-p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
}

.tool-tip-header {
    display: block;
    margin-bottom: 10px;
    font-family: Aleo, sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.choose-date {
    display: inline-block;
    width: 48%;
    margin-bottom: 0;
}

.choosetime {
    display: inline-block;
    width: 48%;
    margin-bottom: 0;
    float: right;
}

.form-headline {
    margin-bottom: 10px;
    font-family: Aleo, sans-serif;
}

.white-arrow {
    position: relative;
    left: -70px;
    display: block;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}

.thank-you-container {
    position: relative;
    top: -250px;
    display: block;
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-bottom: -135px;
    margin-left: auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 1px 2px 1px rgba(93, 110, 128, .35);
}

.thank-you-image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.thank-you-text {
    padding: 15px;
    font-size: 16px;
}

.thank-you-header {
    font-size: 18px;
}

.get-answers {
    color: #fc6d1d;
}

.form-block {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 350px;
    float: right;
}

.form-block.project-page {
    top: -90px;
    display: block;
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    float: none;
}

.note-top {
    position: relative;
    top: 1px;
    width: 100%;
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.home-headline {
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #242c3d;
    font-size: 50px;
    line-height: 60px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 2px;
}

.form-header {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}

.why-link {
    font-size: 13px;
    cursor: pointer;
}

.tooltip-center {
    position: relative;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: block;
    margin-right: auto;
    margin-left: auto;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    text-align: center;
}

.tooltip-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(93, 110, 128, .85);
    cursor: pointer;
}

.quote-image {
    position: relative;
    display: block;
    margin: -30px auto 15px;
}

.close-icon {
    position: relative;
    width: auto;
    height: auto;
    float: left;
}

.close-icon-block {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
}

.page-wrapper {
    position: relative;
    height: 100%;
    min-height: 100%;
}

.content {
    margin-top: 45px;
    padding-bottom: 100px;
}

.headline-section {
    display: block;
    width: 100%;
    min-height: 600px;
    margin-right: auto;
    margin-left: auto;
    background-color: #b6d6e2;
    background-image: url('/static/img/Background.jpg');
    background-position: 50% 101px;
    background-size: cover;
    background-repeat: no-repeat;
    .container.main {
        z-index: 1000;
    }
    .how-to-use-maps-container {
        margin-top: 20px;
        display: block;
        a.how-to-use-maps {
            vertical-align: top;
            line-height: 1.8em;
            margin: 0;
            background: rgba(20, 40, 69, 0.6);
        }
    }
}

.header {
    display: block;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
}

.farmpin-wordmark {
    position: absolute;
    top: 20px;
    margin-left: 40px;
    color: #fff;
    font-size: 25px;
    line-height: 25px;
    font-weight: 500;
}

.farmpin-logo {
    display: inline-block;
    height: 35px;
    width: auto;
}

.headline-block {
    position: relative;
    display: block;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
}

.subtext {
    margin-top: 10px;
    color: rgba(36, 44, 61, .75);
}

.subtext.how {
    color: hsla(0, 0%, 100%, .75);
}

.how-section {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-top: 80px;
    padding-bottom: 50px;
    background-image: -webkit-radial-gradient(circle farthest-side at 50% 100%, #3a4251, #222c3e);
    background-image: radial-gradient(circle farthest-side at 50% 100%, #3a4251, #222c3e);
    text-align: center;
}

.how {
    color: #fff;
}

.how-step {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: 25%;
    height: 100%;
    float: left;
}

.how-text {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 5px;
    color: #fff;
    font-size: 17px;
    font-weight: 300;
}

.step-number {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 40px;
    height: 40px;
    margin-top: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 3px solid #576374;
    border-radius: 29px;
    color: #576374;
    font-size: 22px;
    font-weight: 700;
}

.step-header {
    color: #fc6d1d;
    font-size: 18px;
}

.how-arrow-container {
    display: inline-block;
    width: 12.5%;
    height: 100%;
    float: left;
    background-image: url('/static/img/Arrow_1.svg');
    background-position: 100% 50%;
    background-size: 15px;
    background-repeat: no-repeat;
}

.how-arrow {
    position: relative;
    top: 50%;
    margin-right: 7px;
    margin-left: 8px;
    border-top: 2px dashed #5f6878;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}

.how-block {
    margin-top: 50px;
}

.arc-arrow {
    width: 60%;
}

.steps-block {
    height: 260px;
}

.partners-section {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: -webkit-radial-gradient(circle farthest-side at 50% 100%, #3a4251, #222c3e);
    background-image: radial-gradient(circle farthest-side at 50% 100%, #3a4251, #222c3e);
    text-align: center;
}

.partners {
    color: #fff;
}

.testimonial-name-place {
    font-size: 16px;
}

.bold {
    text-decoration: none;
}

.map {
    max-width: 600px;
    width: 100%;
}

.arrow {
    position: relative;
    left: 5px;
    display: block;
    float: left;
    clear: left;
    top: 5px;
}

._1-day {
    margin-top: 5px;
    color: rgba(36, 44, 61, .75);
    font-size: 14px;
    text-align: center;
}

._1-day.bottom {
    display: block;
    float: none;
    clear: none;
    text-align: center;
}

.form-confirmation-bg {
    background-color: transparent;
}

.form-error-bg {
    border-radius: 5px;
}

.contact-details-usage {
    margin-top: 5px;
    margin-bottom: 10px;
    color: rgba(36, 44, 61, .75);
    font-size: 14px;
    line-height: 18px;
}

.testimonial-tile,
.fact {
    vertical-align: top;
}

textarea {
    resize: none;
    border: 1px solid #b2bac3;
    border-radius: 3px;
    padding: 7px;
    min-height: 100px;
    font-size: 1.1em;
    background-color: #FAFAFA;
    color: #252c3c;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/* map start */
input.search {
    @include border-radius(2px);
    @include tile-box-shadow;
    width: 300px;
    height: 45px;
    margin: 0;
    display: block;
    border: 0;
    &:focus {
        @include box-shadow-custom(none);
        border: 0;
    }
}

input#search-address::-webkit-input-placeholder {
    color: #999;
}

input#search-address::-moz-placeholder {
    color: #999;
}

input#search-address:-ms-input-placeholder {
    color: #999;
}

input#search-address:-moz-placeholder {
    color: #999;
}

.map-image {
    position: relative;
    max-width: 700px;
    width: 100%;
    background: url("/static/img/map-screenshot.jpg") no-repeat;
    height: 200px;
    overflow: hidden;
    @include border-radius(5px);
    @include box-shadow-custom(0 2px 6px rgba(0, 0, 0, 0.3));
    .map-btn {
        font-weight: lighter;
        background-color: rgba(18, 64, 72, 0.55);
        color: white;
        padding: 1em 1.5em;
        @include centralise;
        &:hover {
            text-decoration: none;
            background-color: rgba(18, 64, 72, 0.70);
        }
    }
}

.map-full-screen {
    overflow: hidden;
    .header-section {
        padding: 8px 20px;
        position: relative;
        z-index: 1000;
        .header {
            max-width: none;
            img.farmpin-logo {
                width: 15px;
                height: auto;
                vertical-align: top;
            }
            .farmpin-wordmark {
                margin-left: 25px;
                font-size: 20px;
                top: 7px;
            }
        }
    }
    &.select-fields .farmer-name {
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 1000;
    }
    .how-to-use-maps-container {
        z-index: 1000;
        position: absolute;
        left: 49%;
        top: 24px;
        .how-to-use-maps {
            display: none;
        }
        img.birdie {
            margin-left: -70px;
        }
    }
    .actions-pane {
        position: absolute;
        top: 65px;
        left: 20px;
        z-index: 2;
        .search {
            @include border-radius(3px);
            padding: 10px 15px;
            position: relative;
            background-color: rgba(21, 36, 51, 0.5);
            input {
                background-color: #FAFAFA;
                padding-left: 35px;
            }
            img.search-icon {
                position: absolute;
                top: 51px;
                left: 24px;
                z-index: 100;
            }
            img.search-close-icon {
                position: absolute;
                top: 50px;
                right: 25px;
                z-index: 100;
                cursor: pointer;
            }
            p {
                text-shadow: 0 0 2px rgba(0, 0, 0, 1);
                margin-bottom: 3px;
                color: white;
            }
            .use-location {
                text-align: right;
                text-shadow: 0 0 2px rgba(0, 0, 0, 1);
                margin-top: 4px;
                display: block;
                color: white;
                text-decoration: underline;
                font-size: 0.9em;
            }
        }
        .advisory-main {
            @include tile-box-shadow;
            margin-top: 15px;
            padding: 13px;
            background-color: #FFFFFF;
            border-radius: 3px;
            h4 {
                margin-top: 2px;
            }
        }
    }

    .date-bar {
        position: absolute;
        z-index: 1002;
        left: 0;
        background-color: #F2F2F2;
        height: 100%;
        width: 120px;
        margin-bottom: -86px;
        overflow-y: auto;
        padding-bottom: 95px;
        padding-top: 5px;
        .year-select {
            background-color: #E5E5E5;
            color: #142944;
            font-size: 18px;
            line-height: 18px;
            text-align: center;
            padding: 8px;
        }
        .arrow-up {
            width: 0;
            height: 0;
            margin: 10px auto;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-bottom: 10px solid #9A9EAB;
        }
        button.upgrade-now {
            @include border-radius(5px);
            @include box-shadow-custom(0 1px 2px 0 rgba(36, 44, 61, 0.45));
            background: $call-to-action-colour bottom center url(/static/img/upgrade.svg) no-repeat;
            padding: 6px 0;
            margin-top: 3px;
            height: 80px;
            width: 100px;
            color: white;
            font-weight: 400;
            position: relative;
            span {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 5px auto;
            }
        }
        button {
            display: block;
            margin: 9px auto 0;
            color: #142944;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            height: 70px;
            width: 86px;
            border-radius: 3px;
            background-color: #FFFFFF;
            box-shadow: 0 1px 1px 0 rgba(37, 44, 60, 0.38);
            img {
                display: block;
                margin: 4px auto;
            }
        }

        button.upgrade-btn {
            height: 70px;
            width: 86px;
            box-shadow: none;
            border: 2px dashed rgba(37, 44, 60, 0.38);
            border-radius: 3px;
            background-color: #F2F2F2;
        }

        button.dateSelected {
            background-color: #142944;
            color: #FFFFFF;
        }

        button.date-selector {
            .pin-count {
                position: relative;
                top: -65px;
                right: -70px;
                border-radius: 50%;
                background-color: #5d6c7b;
                width: 15px;
                height: 15px;
                span {
                    position: relative;
                    top: -3px;
                    color: #fff;
                    font-size: small;
                }
            }
        }
    }

    .image-type-bar {
        position: absolute;
        z-index: 1002;
        right: 150px;
        left: 150px;
        margin-top: -64px;
        text-align: center;
        .image-type-bar-inner {
            margin: 0 auto;
            padding: 4px;
            border-radius: 4px;
            background-color: #e7e7e7;
            display: inline-block;
            button:last-child {
                margin-right: 0;
            }
            .selected-map-image-type {
                border-radius: 3px;
                background-color: #142944;
                color: #FFFFFF;
                font-weight: bold;
            }
        }
    }

    .image-type {
        background-color: #FFFFFF;
        height: 38px;
        width: 130px;
        color: #142944;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin-right: 5px;
        border-radius: 3px;
    }

    .image-type.selectedImageType {
        border-radius: 3px;
        background-color: #142944;
        color: #FFFFFF;
        font-weight: bold;
    }

    .index-bar {
        position: absolute;
        z-index: 100;
        right: 10px;
        bottom: 30px;
    }

    #mapDiv {
        z-index: 1;
        width: 100%;
        height: 100% !important;
        overflow: hidden;
        position: absolute;
        top: 0%;
        right: 0;
        left: 0;
        bottom: 0;
        &:after {
            content: "";
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(36, 38, 44, .55);
        }
        &.hidden-pseudo:after {
            display: none;
        }
        &.map-images:after {
            content: none;
        }
        .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
            bottom: 128px !important;
        }
    }

    div#thank-you-parent {
        z-index: 2;
        padding: 20px;
        width: 390px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 3px 0 rgba(45, 57, 71, 0.41);
        border-radius: 4px;
        @include centralise;
        .logo-icon {
            @include border-radius(50%);
            background: white;
            padding: 10px 10px 10px 14px;
            height: 50px;
            width: 50px;
            margin: 0 auto;
            margin-top: -35px;
            text-align: center;
        }
        div {
            h4 {
                margin-top: 0;
                font-weight: bold;
            }
            p {
                margin-top: 10px;
                font-family: Ubuntu;
                line-height: 1.5em;
                font-size: 16px;
                color: #1A3146;
            }
            p.failed-to-geo-locate {
                white-space: pre-wrap;
                border: 1px solid #E01400;
                color: #E01400;
                border-radius: 5px;
                padding: 10px;
                margin-top: 20px;
                position: relative;
                &:before {
                    content: "";
                    background: url(/static/img/Triangle.svg) no-repeat;
                    width: 15px;
                    height: 7px;
                    position: absolute;
                    top: -7px;
                }
            }
            .animated-gif-map {
                text-align: center;
                img {
                    width: 74px;
                    height: auto;
                }
            }

            button#find-fields {
                color: #fff;
                background: #F57713;
                height: 46px;
                width: 100%;
                font-family: Ubuntu;
                font-size: 16px;
                font-weight: 400;
                margin-top: 20px;
                text-transform: none;

            }

            button#choose-my-fields {
                color: #fff;
                background: #F57713;
                height: 46px;
                width: 100%;
                font-family: Ubuntu;
                font-size: 16px;
                font-weight: 400;
                margin-top: 20px;
                text-transform: none;
            }

            input#search-address-modal {
                background-color: #fff;
                box-shadow: none;
                border: 1px solid #124048;
                margin-top: 10px;
                padding-left: 40px;
                padding-right: 40px;
                width: 100%;
                &::-webkit-input-placeholder {
                    font-size: 14px;
                    color: rgb(145, 157, 157);
                    font-style: italic;
                }
                &::-moz-placeholder {
                    font-size: 14px;
                    color: rgb(145, 157, 157);
                    font-style: italic;
                }
                &:-ms-input-placeholder {
                    font-size: 14px;
                    color: rgb(145, 157, 157);
                    font-style: italic;
                }
                &::-moz-placeholder {
                    font-size: 14px;
                    color: rgb(145, 157, 157);
                    font-style: italic;
                }
            }

            div.a-parent {
                text-align: left;
                padding: 20px 0 0 0;
                position: relative;
                .search-icon-modal {
                    width: 20px;
                    background-position: 100%;
                    position: absolute;
                    left: 12px;
                    bottom: 13px;
                }
                .search-close-modal {
                    cursor: pointer;
                    width: 20px;
                    background-position: 100%;
                    position: absolute;
                    right: 12px;
                    bottom: 13px;
                }
            }
        }

    }
/*
    .pin-info-window {
        width: 420px;
        padding: 15px 10px 0 5px;

        .controls {
            margin-bottom: 10px;
            div:first-child {
                text-align: left;
            }
            div:last-child {
                text-align: right;
            }
        }

        .controls.bottom {
            height: 45px;
            border-radius: 3px;
            margin-left: -9px;
            margin-right: -7px;
            padding: 13px 13px 0 10px;
            background: #F2F2F2;
            border-radius: 0 0 2px 2px;
            button.delete {
                padding: 0 3px;
                height: 20px;
                line-height: 20px;
                text-transform: none;
            }
        }
    }*/
}

.map-modal-overlay {
    position: fixed;
    z-index: 1100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(36, 38, 44, .55);
}

.dark-overlay {
    background-color: rgba(21, 36, 51, 0.8);
}

.map-modal {
    position: absolute;
    z-index: 2000;
    padding: 20px;
    border-radius: 4px;
    background-color: #FFFFFF;
    left: 0;
    right: 0;
    margin: 40px auto;
    width: 589px;
    .close-button {
        position: absolute;
        top: -30px;
        right: 0;
        color: #fff;
        cursor: pointer;
    }
    .rocket {
        position: absolute;
        left: -30px;
        bottom: -14px;
        img {
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
    }
    .header-icon {
        @include border-radius(50%);
        background-color: white;
        width: 120px;
        height: 120px;
        margin: -50px auto 0 auto;
        position: relative;
        img {
            @include centralise;
            width: 100%;
            height: auto;
        }
    }
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        margin: 20px auto;
        max-width: 400px;
    }
    .video-wrapper iframe {
        @include border-radius(6px);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .row {
        margin-bottom: 0;
        div {
            span.upgrade-span {
                color: #5D6E80;
                font-size: 16px;
                line-height: 18px;
            }
            span.max-farm-size {
                color: #5D6E80;
                margin-top: 6px;
                font-size: 14px;
                font-style: italic;
                line-height: 19px;
            }
            a {
                margin-top: 10px;
            }
        }
    }
}

.header.field-map-header {
    height: 70px;
    .cta {
        position: relative;
        top: calc(50% - 12px);
        font-size: 14px;
    }
    .upgrade-now-bar {
        margin: 0 auto;
        display: block;
        left: 0;
        top: 20px;
        right: 0;
        text-align: center;
        position: absolute;
        p {
            color: #fff;
            font-size: 16px;
            line-height: 18px;
        }
        span {
            cursor: pointer;
            color: rgba(252, 109, 29, 1);
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

.pin-actions label {
    color: white;
}

p.farmer-name {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    margin-right: 25px;
    font-size: 16px;
    text-align: center;
    top: 32px;

    /* TogetherJS button */
    a.btn {
        background-color: #FFF;
        color: #242c3d;
        text-transform: none;
        padding: 0 10px;
        font-size: 16px;

        &.stop {
            color: #dd1313;
        }

        i.left {
            margin-right: 5px;
        }
    }
}

/* TogetherJS Dock styling */
#togetherjs-dock.togetherjs-dock-right {
    top: 90px;
    display: none;
}

.advisory-main {
    h4 {
        margin: 0;
    }

    p.area {
        font-family: Ubuntu;
        font-size: 14px;
        line-height: 16px;
        color: #3F454B;
        margin: 5px 0;
    }

    p.crop-type {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 18px;
        color: #3F454B;
        padding: 5px 0 7px 0;
        font-weight: 300;
    }

    #cropTypes {
        height: 40px;
    }

    div.clear-parent {
        text-align: right;

        a {
            font-family: Ubuntu;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #E91100;
        }
    }

    .cta {
        margin-top: 20px;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

}

.map-back-btn {
    z-index: 1;
    width: 60%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 24px;
    color: #fff;
    background: #F57713;
    height: 46px;
}

#find-fields {
    position: relative;
    padding-left: 45px;
    text-align: left;
}

#find-fields:before {
    content: "";
    background: url(/static/img/Current-Location.svg) no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 10px;
    top: 10px;
    background-size: 100%;
}

#find-fields.finding-location:before {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 3000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 3000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 3000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.or-search-input {
    position: relative;
    width: 100%;
    border-bottom: 1px solid gray;
    margin: 35px 0 5px;
}

.or-search-input:before {
    content: 'OR';
    width: 38px;
    text-align: center;
    background-color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -12px;
    font-weight: 500;
}

.map-modal .row div a.upgrade-email-link {
    margin-left: 35px;
}

/* map end */

/* ----- EVERYTHING ABOVE THIS LINE IS PEACHY ----- */

/* ----- MEDIA QUERIES FROM HERE ----- */
@media (max-width: 991px) {
    body {
        font-size: 15px;
        line-height: 23px;
    }
    h1 {
        font-size: 36px;
        line-height: 42px;
    }
    h2 {
        line-height: 48px;
    }
    .logo {
        width: 191px;
        padding: 8px 20px;
    }
    .container {
        padding-right: 20px;
        padding-left: 20px;
    }
    .container.why {
        padding-bottom: 160px;
    }
    .container.top {
        padding-top: 30px;
    }
    .welcome-message {
        left: 300px;
        width: 50%;
        margin-bottom: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 30px;
        line-height: 35px;
    }
    .contact-form.tile {
        clear: none;
    }
    .why-block {
        position: relative;
        width: 55%;
        float: left;
    }
    .about-me-text {
        margin-top: 1px;
    }
    .testimonials-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .field {
        height: 39px;
    }
    .testimonials-section .container {
        width: 100%;
        .testimonial-tile {
            margin-right: 10px;
            margin-left: 10px;
            padding: 10px;
        }
    }
    .quote-marks-left {
        left: -15px;
        top: -10px;
        width: 50px;
    }
    .quote-marks-right {
        top: -10px;
        right: -15px;
        width: 50px;
    }
    .bottom-section {
        padding-top: 30px;
        padding-bottom: 80px;
    }
    .about-section .about-block {
        margin: 0 auto;
    }
    .footer.white-text {
        padding-top: 12px;
        padding-bottom: 10px;
    }
    .tick-mark {
        width: 13px;
        margin-top: -4px;
    }
    .hero-img {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        margin-left: 0;
    }
    .form-block {
        top: -10px;
        width: 45%;
        margin-top: 11px;
    }
    .form-block.project-page {
        margin-bottom: -25px;
    }
    .note-top {
        height: 45px;
    }
    .home-headline {
        font-size: 48px;
        line-height: 56px;
    }
    .headline-section {
        min-height: 450px;
    }
    .steps-block {
        height: 320px;
    }
    .map {
        position: absolute;
        left: 10px;
        z-index: 2;
        display: block;
        min-width: 517px;
        margin-bottom: 0;
        text-align: right;
    }
    .arrow {
        z-index: 2;
    }
}

@media (max-width: 650px) {
    .map-full-screen {
        div#search-address-parent {
            left: 20px !important;
            input {
                width: 278px !important;
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .header-section {
        padding: 10px;
    }
    .header .farmpin-logo {
        height: 30px;
    }
    .logo-block {
        .farmpin-wordmark {
            top: 15px;
            margin-left: 30px;
            font-size: 24px;
        }
        .farmpin-logo {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
    .headline-section .container.main {
        padding-top: 18px;
    }

    .map-full-screen {
        .header-section {
            .header .farmpin-wordmark {
                top: 9px;
            }
        }
    }
    h1 {
        margin-bottom: 5px;
        line-height: 36px;
    }
    h2, h2.small {
        margin-top: 0;
        font-size: 32px;
        line-height: 36px;
    }
    .form-top-section.project-page {
        padding-bottom: 80px;
    }
    .container.why {
        padding-bottom: 0;
    }
    .welcome-message {
        position: relative;
        left: 0;
        width: 100%;
        margin-right: 0;
        padding: 10px 10px 10px 15px;
        float: right;
        line-height: 32px;
    }
    .arrow-left {
        left: auto;
        right: 268px;
        bottom: -21px;
        border-right-width: 18px;
        border-bottom-width: 21px;
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    .arrow-left-inside {
        margin-top: 2px;
        margin-left: 5px;
        border-right-width: 15px;
        border-bottom-width: 17px;
    }
    .why-section {
        padding-top: 30px;
        padding-bottom: 0;
    }
    .contact-form.tile {
        display: block;
        width: 100%;
        max-width: 350px;
        margin-right: auto;
        margin-left: auto;
        float: none;
    }
    .why-block {
        overflow: hidden;
        width: 100%;
        float: none;
    }
    .list-item {
        padding-left: 10px;
    }
    .cta.float-right.submit-button-arrow {
        width: 100%;
        background-position: 96% 50%;
    }
    .testimonials-section {
        padding-bottom: 15px;
    }
    .testimonials-wrapper {
        margin-top: 34px;
        margin-bottom: 0;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
    .testimonial-tile {
        position: relative;
        width: 100%;
        max-width: 400px;
        margin-bottom: 25px;
    }
    .quote-marks-left {
        left: 0;
    }
    .quote-marks-right {
        right: 0;
    }
    .hero-img {
        max-width: 600px;
    }
    .form-nav {
        max-width: 370px;
    }
    .form-headline {
        line-height: 44px;
    }
    .thank-you-container {
        margin-bottom: -206px;
    }
    .form-block {
        top: 0;
        display: block;
        width: 350px;
        margin: 30px auto;
        float: none;
    }
    .form-block.project-page {
        margin-bottom: -53px;
    }
    .partners-section {
        padding-top: 30px;
        padding-bottom: 40px;
    }
    .note-top {
        width: 100%;
    }
    .home-headline {
        font-size: 36px;
        line-height: 42px;
    }
    .headline-section {
        min-height: 500px;
    }
    .how-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .how-step {
        display: block;
        width: 100%;
        margin-bottom: 50px;
        float: none;
    }
    .how-arrow-container {
        width: 100%;
        float: none;
        background-image: none;
        background-position: 0;
        background-size: auto;
        background-repeat: repeat;
    }
    .how-arrow {
        top: 0;
        display: block;
        margin-right: auto;
        margin-left: auto;
        border-left: 3px dashed #596372;
        border-top-style: none;
    }
    .steps-block {
        height: auto;
    }
    .map {
        position: static;
    }
}

@media (max-width: 700px) {
    .map-modal {
        width: 90%;
        .close-button {
            top: 5px;
            right: 10px;
            color: #000;
        }
        .rocket {
            display: none;
        }
        .row {
            .col.s2 {
                display: none;
            }
            .col.s10 {
                margin-top: 20px;
                width: 100%;
                a {
                    font-size: 13px;
                }
            }
        }
    }
    .header.field-map-header {
        height: 38px;
        .upgrade-now-bar {
            display: none;
        }
    }

    p.farmer-name {
        top: 16px;
        margin-right: 15px;
    }

    #mapDiv.map-images {
        overflow: hidden;
        bottom: 62px;
        height: auto !important;
    }

    .map-full-screen {
        &.select-fields .farmer-name {
            font-size: $small-text;
        }
        .image-type-bar {
            left: 100px;
            right: 100px;
        }
        .index-bar {
            bottom: 85px;
        }
        .how-to-use-maps-container {
            left: 0;
            top: 7px;
            top: 120px;
            .how-to-use-maps {
                display: inline-block;
            }
            .how-to-use-maps-with-birdie {
                display: none;
            }
        }
        .date-bar {
            white-space: nowrap;
            height: 59px;
            width: 100%;
            bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
            margin-bottom: 0;
            overflow-y: hidden;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            .year-select {
                width: 100%;
                position: fixed;
            }
            button, button.upgrade-now {
                margin-top: 7px;
                height: 40px;
                width: 106px;
                font-size: 14px;
                display: inline;
                margin-right: 5px;
                margin-left: 5px;
                span {
                    position: inherit;
                }
                img {
                    width: 22px;
                    display: inherit;
                }
            }
            button.upgrade-now {
                background-position: -4px 2px;
                background-size: 45px;
                vertical-align: bottom;
                padding-left: 40px;
                white-space: normal;
                text-align: left;
                line-height: 1.1em;
            }
        }
    }
}

@media (max-width: 479px) {
    .map-full-screen {
        .image-type {
            min-width: 100px;
            width: auto;
        }
    }
    .cta.cta-large.get-image {
        min-width: 0;
        width: 95%;
    }
    .container {
        width: 100%;
    }
    h1 {
        font-size: 30px;
        line-height: 34px;
        text-align: center;
    }
    h2, h2.small {
        font-size: 30px;
        line-height: 34px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
    }
    .form-top-section.project-page {
        padding-bottom: 80px;
    }
    .container.why {
        padding-left: 20px;
    }
    .container.top {
        padding-top: 20px;
    }
    .welcome-message {
        width: 100%;
        float: none;
    }
    .arrow-left {
        left: auto;
        right: 57px;
        bottom: -20px;
        -webkit-transform: rotate(270deg) translate(0px, 0);
        -ms-transform: rotate(270deg) translate(0px, 0);
        transform: rotate(270deg) translate(0px, 0);
    }
    .contact-form.tile {
        max-width: 100%;
        padding: 15px;
    }
    .form-header {
        font-size: 18px;
        line-height: 1.3em;
    }
    .why-block {
        margin-top: -15px;
    }
    .cta.float-right.submit-button-arrow {
        background-position: 88% 50%;
    }
    .testimonials-wrapper {
        width: 100%;
        margin-top: 30px;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
    }
    .testimonial-tile {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .quote-marks-left {
        left: -18px;
        top: -28px;
    }
    .quote-marks-right {
        top: -28px;
        right: -18px;
    }
    .hero-img {
        max-width: 100%;
        margin-top: -10px;
        margin-bottom: 0;
    }
    .form-nav {
        max-width: 200px;
    }
    .thank-you-container {
        width: 90%;
    }
    .form-block {
        width: 100%;
        max-width: none;
    }
    .note-top {
        top: 2px;
    }
    .home-headline {
        font-size: 28px;
        line-height: 1.3em;
        letter-spacing: 1px;
    }
    .tooltip-center {
        padding-right: 20px;
        padding-left: 20px;
    }
    .tooltip-container {
        display: none;
    }
    .headline-section {
        min-height: 350px;
    }
    .map {
        min-width: 0;
    }
    ._1-day {
        display: block;
        margin-right: auto;
        margin-left: auto;
        float: none;
        clear: none;
        text-align: center;
        line-height: 1.3em;
    }
}

@media (max-width: 400px) {
    .about-me-list {
        margin-bottom: 20px;
    }
    .cta.submit-button-arrow {
        background-image: none;
        padding-left: 10px;
        padding-right: 10px;
        white-space: normal;
        line-height: 1.2em;
    }
    .map-full-screen {
        .header-section {
            padding-left: 13px;
        }
        .actions-pane {
            top: 40px;
            left: 0;
            right: 0;
            .advisory-main {
                @include border-radius(0);
                margin: 0;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                top: auto;
                .details-parent {
                    h4 {
                        display: none;
                    }
                    p.area {
                        margin: -35px 0 12px 0;
                        color: white;
                        text-shadow: 0 0 2px black;
                        font-size: 1em;
                    }
                    .clear-parent {
                        float: left;
                        padding-top: 12px;
                    }
                }
                .cta {
                    float: right;
                    width: auto;
                    margin: 0;
                }
            }
            .search {
                padding: 5px 10px 8px 11px;
                input#search-address {
                    width: 100%;
                }
                img.search-icon {
                    top: 46px;
                    left: 20px;
                }
                img.search-close-icon {
                    top: 44px;
                    right: 15px;
                }
                .use-location {
                    margin-top: 7px;
                }
            }
        }
        div#thank-you-parent {
            margin-top: 20px;
            width: 95% !important;

            div {
                h4 {
                    margin-top: 0;
                    font-weight: bold;
                }
                p {
                    margin-top: 10px;
                }
                p.failed-to-geo-locate {
                    font-size: 12px;
                }
                button#find-fields {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 315px) {

}

@media only screen and (min-width: 993px) {
    .testimonials-section .container {
        width: 100%;
        .testimonial-tile {
            width: 29%;
        }
        .quote-marks-right {
            right: -32px;
        }
        .quote-marks-left {
            left: -32px;
        }
    }
}

@media (max-width: 479px) {

    /*
    .map-full-screen .pin-info-window {
        width: 265px;
        textarea {
            width: 96% !important;
            margin-bottom: 7px;
        }

        .controls.bottom {
            margin-left: -10px;
            margin-right: -46px;
        }

        .top {
            .col:last-child {
                div {
                    label {
                        padding-left: 29px;
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }*/

}